// import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"
const Products = () => (
  <section
    id="products"
    style={{
      background: `#293039`,
      textAlign: `center`,
      color: "#FFFFFF",
    }}
  >
    <h1 style={{ fontSize: `3.25rem`, paddingTop: `2.45rem` }}>PRODUTOS</h1>
    <div
      style={{
        display: `flex`,
        justifyContent: `space-around`,
        padding: `1.4rem`,
        flexWrap: `wrap`,
        maxWidth: 1160,
        margin: `0 auto`,
      }}
    >
      <div style={{ width: 350 }}>
        <div style={{ width: 200, margin: `0 auto` }}>
          <Image alt="Outdoor" filename="produto1.png" />
        </div>
        <h2 style={{ margin: `1rem` }}>Outdoor</h2>
        <p>São mais de 100 pontos para expor sua marca.</p>
      </div>

      <div style={{ width: 350 }}>
        <div style={{ width: 142, margin: `0 auto` }}>
          <Image alt="Painel de LED" filename="produto2.png" />
        </div>
        <h2 style={{ margin: `1rem` }}>Painel de LED</h2>
        <p>
          Divulgue sua empresa nos painéis de LED localizados em pontos
          estratégicos de Barretos: North Shopping e no centro da cidade.
        </p>
      </div>

      <div style={{ width: 350 }}>
        <div style={{ width: 335, margin: `0 auto` }}>
          <Image alt="Frontlight" filename="produto3.png" />
        </div>
        <h2 style={{ margin: `1rem` }}>Frontlight</h2>
        <p>Localizado na Av. 43, uma das principais entradas da cidade.</p>
      </div>
    </div>
  </section>
)

export default Products
