import React, { useState, useEffect, useCallback, useRef } from "react"
import api from "../services/api"
import InputMask from "react-input-mask"
import Recaptcha from "react-recaptcha"
import { toast } from "react-toastify"
import bg from "../images/contact_bg.png"

const Contact = props => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [cel, setCel] = useState("")
  const [message, setMessage] = useState("")

  const [loading, setLoading] = useState(false)

  const recaptchaInstance = useRef({})

  const executeCaptcha = function(e) {
    e.preventDefault()

    if (!name || !email || !message) {
      toast.error(
        "Os campos nome, e-mail e mensagem são obrigatórios. Preencha-os por favor.",
        {
          bodyClassName: "toast-body",
        }
      )
      return
    }

    recaptchaInstance.current.execute()
  }

  const resetRecaptcha = () => {
    recaptchaInstance.current.reset()
  }

  const getDataContact = useCallback(() => {
    return {
      name,
      email,
      phone,
      cel,
      message,
    }
  }, [name, email, phone, cel, message])

  if (typeof window !== `undefined`)
    window.getDataContact = getDataContact;

  const submitForm = resCaptcha => {
    setLoading(true)

    api
      .post("contacts", {
        "g-recaptcha-response": resCaptcha,
        ... ( typeof window !== `undefined` ? window.getDataContact() : getDataContact ),
      })
      .then(function(response) {
        toast.dismiss()
        toast.success("Sua mensagem foi enviada! Logo te responderemos.", {
          className: "toast--sucess",
          bodyClassName: "toast-body",
        })
        resetForm()
      })
      .catch(function(error) {
        toast.dismiss()
        toast.error("Seus dados parecem estar errados, verifique por favor.", {
          bodyClassName: "toast-body",
        })
        console.error(error)
      })
      .finally(function() {
        setLoading(false)
        resetRecaptcha()
      })
  }

  const resetForm = () => {
    document.getElementById("contact-form").reset()
    setName("")
    setEmail("")
    setPhone("")
    setCel("")
    setMessage("")
  }

  useEffect(() => {
    setPhone(phone.replace(/[()-\s]/g, ""))
  }, [phone])

  useEffect(() => {
    setCel(cel.replace(/[()-\s]/g, ""))
  }, [cel])

  return (
    <section
      id="contact"
      style={{
        backgroundImage: `url(${bg})`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        padding: `2.4rem 0`,
      }}
    >
      <div
        style={{
          maxWidth: 1160,
        }}
      >
        <h1
          style={{
            fontSize: `3.25rem`,
            marginBottom: `2.5rem`,
            padding: `0 1.4rem`,
            color: `#add137`,
          }}
        >
          ENTRE EM CONTATO
        </h1>
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
            flexWrap: `wrap`,
          }}
        >
          <form
            id="contact-form"
            style={{
              display: `flex`,
              flexDirection: `column`,
              padding: `0 1.4rem`,
              color: `white`,
            }}
          >
            <label>NOME COMPLETO</label>
            <input
              type="text"
              name="name"
              id="name-contact"
              required
              onChange={e => setName(e.target.value)}
              disabled={loading}
            />

            <label>E-MAIL</label>
            <input
              type="email"
              name="email"
              id="email-contact"
              required
              onChange={e => setEmail(e.target.value)}
              disabled={loading}
            />

            <label>TELEFONE</label>
            <InputMask
              mask="(99) 9999-9999"
              name="phone"
              id="phone"
              onChange={e => setPhone(e.target.value)}
              disabled={loading}
            />

            <label>CELULAR</label>
            <InputMask
              mask="(99) 9 9999-9999"
              name="cel"
              id="cel"
              onChange={e => setCel(e.target.value)}
              disabled={loading}
            />

            <label>MENSAGEM</label>
            <textarea
              type="text"
              name="message"
              id="message"
              rows="5"
              required
              onChange={e => setMessage(e.target.value)}
              disabled={loading}
            />

            <button
              type="submit"
              style={{
                marginTop: `1.4rem`,
                padding: `.5rem`,
                fontWeight: `bold`,
                color: `#374800`,
                cursor: `pointer`,
              }}
              onClick={executeCaptcha}
              disabled={loading}
            >
              ENVIAR
            </button>
            {typeof window !== "undefined" && <Recaptcha
              elementID="recaptcha_contact"
              ref={recaptchaInstance}
              sitekey="6Lcu7c0UAAAAAGINLq_gLj-kj2_vSvFn981_UlrB"
              size="invisible"
              verifyCallback={submitForm}
              theme="dark"
            />}
          </form>
          <div
            id="contact-info"
            style={{ padding: `0 1.4rem`, color: `white` }}
          >
            <div style={{ padding: `.5rem 0` }}>
              <p style={{ color: `#add137` }}>ENDEREÇO</p>
              <p>Praça Joel Waldo, 1, Barretos-SP, CEP: 14.781-574</p>
            </div>
            <div style={{ padding: `.5rem 0` }}>
              <p style={{ color: `#add137` }}>E-MAIL</p>
              <p>emvista@grupomonteirodebarros.com.br</p>
            </div>
            <div style={{ padding: `.5rem 0` }}>
              <p style={{ color: `#add137` }}>TELEFONE</p>
              <p>(17) 3321-7070</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
