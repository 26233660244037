// import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"
const KnowMore = () => (
  <section
    id="know_more"
    style={{
      background: `#add137`,
      textAlign: `center`,
    }}
  >
    <h1 style={{ fontSize: `3.25rem`, paddingTop: `2.45rem` }}>SAIBA MAIS</h1>
    <div
      style={{
        display: `flex`,
        justifyContent: `space-around`,
        padding: `1.4rem`,
        flexWrap: `wrap`,
        maxWidth: 1160,
        margin: `0 auto`,
      }}
    >
      <div style={{ width: 350 }}>
        <div style={{ width: 300, margin: `0 auto` }}>
          <iframe
            width="300"
            height="170"
            src="https://www.youtube.com/embed/CVLu4V4D7y4"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
        <h2 style={{ margin: `1rem` }}>Festa do Peão de Barretos</h2>
        <p>
          A <strong>emvista</strong> possui os melhores pontos distribuídos ao
          longo da principal via de acesso a Festa do Peão de Barretos.{" "}
        </p>
      </div>

      <a
        href="http://esouth.logycware.com/EmVista/disponibilidade/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: 350 }}
      >
        <div style={{ width: 300, margin: `0 auto` }}>
          <Image alt="Outdoor" filename="saiba_mais1.png" />
        </div>
        <h2 style={{ margin: `1rem` }}>
          Veja a disponibilidade de cada painel
        </h2>
        <p>
          Clique aqui e saiba a disponibilidade de cada painel na bi-semana de
          sua preferência.
        </p>
      </a>

      <a
        href="http://esouth.logycware.com/EmVista/locais/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: 350 }}
      >
        <div style={{ width: 300, margin: `0 auto` }}>
          <Image alt="Map" filename="saiba_mais2.png" />
        </div>
        <h2 style={{ margin: `1rem` }}>Conheça todos os pontos</h2>
        <p>
          Clique aqui e conheça a localização com foto de cada paínel da{" "}
          <strong>emvista</strong>.
        </p>
      </a>
    </div>
  </section>
)

export default KnowMore
