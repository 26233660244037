import React, { useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about"
import Products from "../components/products"
import KnowMore from "../components/know_more"
import Contact from "../components/contact"
import Ebook from "../components/ebook"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

toast.configure()

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="emvista outdoor" />
      <About />
      <Products />
      <KnowMore />
      <Contact />
      <Ebook />
    </Layout>
  )
}

export default IndexPage
