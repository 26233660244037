// import PropTypes from "prop-types"
import React from "react"
import Image from "../components/image"
const About = () => (
  <div>
    <a
      title="O Diário Interativo"
      style={{
        display: 'block',
        width: "100%"
      }}
      href="https://odiarioonline.com.br/cadastro/planos"
      target="_blank"
    >
      <img
        style={{
          display: 'block',
          width: "100%",
          margin: 0
        }}
        src={ require('../images/super-banner.gif') }
        alt="Logotipo dos indepentendes - Barretos-SP"
      />
    </a>
    <section
      id="about"
      style={{
        background: `#add137`,
        display: `flex`,
        minHeight: 400,
      }}
    >
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          width: `100%`,
          padding: `2.4rem`,
        }}
      >
        <div style={{ maxWidth: 500 }}>
          <h1 style={{ fontSize: `3.25rem` }}>SOBRE NÓS</h1>
          <p>
              Criada em 2014 para compor o portfólio multiplataforma de veículos de

              comunicação do Grupo Monteiro de Barros, a <strong>emvista</strong> oferece diversas

              soluções de mídia exterior para divulgar a sua marca em Barretos, Colina, Colômbia e Bebedouro: Outdoor em papel e lona, LED e Front Light.
              <br/><br/>
              Nossa missão é garantir visibilidade de forma estratégica nos melhores pontos de Barretos e região!
          </p>
        </div>
      </div>
      <div
        id="about-img"
        style={{ width: 500, boxShadow: `-5px 0px 8px 2px rgba(0,0,0,0.2)` }}
      >
        <Image
          alt="Logotipo dos indepentendes - Barretos-SP"
          filename="independentes.png"
        />
      </div>
    </section>
  </div>
)

export default About
