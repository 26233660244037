// import PropTypes from "prop-types"
import React, { useState, useCallback, useRef } from "react"
import api from "../services/api"
import Image from "../components/image"
import Recaptcha from "react-recaptcha"
import { toast } from "react-toastify"

const Ebook = props => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const [loading, setLoading] = useState(false)

  const recaptchaInstance = useRef({})

  const executeCaptcha = function(e) {
    e.preventDefault()

    if (!name || !email) {
      toast.error(
        "Os campos nome e e-mail são obrigatórios. Preencha-os por favor.",
        {
          bodyClassName: "toast-body",
        }
      )
      return
    }

    recaptchaInstance.current.execute()
  }

  const resetRecaptcha = () => {
    recaptchaInstance.current.reset()
  }

  const getDataEbook = useCallback(() => {
    return {
      name,
      email,
    }
  }, [name, email])

  if (typeof window !== `undefined`)
    window.getDataEbook = getDataEbook;

  const submitForm = resCaptcha => {
    setLoading(true)

    api
      .post("ebooks", {
        "g-recaptcha-response": resCaptcha,
        ...( typeof window !== `undefined` ? window.getDataEbook() : getDataEbook() ),
      })
      .then(function(response) {
        toast.dismiss()
        toast.success("Enviamos seu e-book gratuíto!", {
          className: "toast--sucess",
          bodyClassName: "toast-body",
        })
        resetForm()
      })
      .catch(function(error) {
        toast.dismiss()
        toast.error("Seus dados parecem estar errados, verifique por favor.", {
          bodyClassName: "toast-body",
        })
        console.error(error)
      })
      .finally(function() {
        setLoading(false)
        resetRecaptcha()
      })
  }

  const resetForm = () => {
    document.getElementById("ebook-form").reset()
    setName("")
    setEmail("")
  }

  return (
    <section
      id="ebook"
      style={{
        background: `#EAEAEA`,
        display: `flex`,
      }}
    >
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          padding: `1.4rem`,
          maxWidth: 1160,
          margin: `0 auto`,
        }}
      >
        <div
          id="ebook-img"
          style={{
            minWidth: 275,
            minHeight: 400,
            marginRight: `2.4rem`,
            boxShadow: `0px 5px 10px -1px rgba(0,0,0,0.75)`,
            backgroundColor: `#293039`,
          }}
        >
          <Image
            alt="Capa do ebook - Descubra 5 dicas para criar um anúncio de mídia exterior eficaz"
            filename="ebook.png"
          />
        </div>
        <div style={{ width: `100%` }}>
          <h1 style={{ fontSize: `2.25rem` }}>
            Gostaria de saber como criar um anúncio para mídia exterior?
          </h1>
          <p>
            Preencha o cadastro e receba gratuitamente nosso e-book:{" "}
            <u>
              <strong>
                Descubra 5 dicas para criar um anúncio de mídia exterior eficaz
              </strong>
            </u>
          </p>
          <form
            id="ebook-form"
            style={{
              display: `flex`,
              paddingTop: `1.4rem`,
              width: `100%`,
              flexWrap: `wrap`,
              justifyContent: `center`,
            }}
          >
            <label>NOME COMPLETO: </label>
            <input
              type="text"
              name="name"
              id="name-ebook"
              required
              onChange={e => setName(e.target.value)}
              disabled={loading}
            />

            <label>E-MAIL: </label>
            <input
              type="email"
              name="email"
              id="email-ebook"
              required
              onChange={e => setEmail(e.target.value)}
              disabled={loading}
            />

            <button
              style={{
                fontWeight: `bold`,
                color: `#374800`,
                padding: `1rem`,
                cursor: `pointer`,
                margin: `1.4rem auto`,
              }}
              type="submit"
              onClick={executeCaptcha}
              disabled={loading}
            >
              GANHAR E-BOOK!
            </button>
            {typeof window !== "undefined" && <Recaptcha
              elementID="recaptcha_ebook"
              ref={recaptchaInstance}
              sitekey="6Lcu7c0UAAAAAGINLq_gLj-kj2_vSvFn981_UlrB"
              size="invisible"
              verifyCallback={submitForm}
              theme="dark"
            />}
          </form>
        </div>
      </div>
    </section>
  )
}

export default Ebook
