import axios from "axios"

const api = axios.create({
  // baseURL: "http://192.168.0.33:8001/api/v1",
  baseURL: "https://api.emvistaoutdoor.com.br/api/v1",
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
})

export default api
